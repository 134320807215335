.footer {
  padding: 40px 0;
  background: $color-main;

  @include media-up(md) {
    padding: 70px 0;
  }

  .footer-head {
    @include media-up(md) {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: end;
      -webkit-align-items: flex-end;
      -ms-flex-align: end;
      align-items: flex-end;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: reverse;
      -webkit-flex-flow: row-reverse;
      -ms-flex-flow: row-reverse;
      flex-flow: row-reverse;
    }

    .footer-head__logo {
      width: 100%;
      max-width: 240px;
      margin: 35px auto 0;

      @include media-up(md) {
        max-width: 300px;
        margin: 0;
      }
    }

    .footer-head__langage {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: end;
      -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
      justify-content: flex-end;

      .item {
        a,
        span {
          display: block;
          width: 90px;
          height: 30px;
          line-height: 26px;
          text-align: center;
          border-color: $color-white;
          border-style: solid;
          border-width: 1px 1px 1px 0;

          @include media-up(md) {
            width: 110px;
            height: 35px;
            line-height: 30px;
          }

          @include media-down(xs) {
            width: 50px;
            height: 30px;
            font-size: 10px;
          }
        }

        &:first-child {
          a,
          span {
            border-width: 1px 1px 1px 1px;
          }
        }

        a {
          background: $color-main;
          color: $color-white;

          @include media-up(md) {
            &:hover {
              background: $color-white;
              color: $color-main;
            }
          }
        }

        span {
          background: $color-white;
          color: $color-main;
        }
      }
    }
  }

  .footer-info {
    width: 100%;
    max-width: 350px;
    margin: 20px auto 0;

    @include media-up(md) {
      max-width: none;
      margin: 20px 0 0;
    }

    table {
      width: 100%;

      th {
        font-size: 14px;
        color: $color-white;
        padding: 8px 0;
        width: 70px;
        vertical-align: text-top;

        @include media-up(md) {
          font-size: 16px;
          width: 90px;
        }
      }

      td {
        color: $color-white;
        padding: 3px 0;

        @include media-up(md) {
          padding: 8px 0;
        }

        a {
          color: $color-white;

          @include media-up(md) {
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  .footer-link {
    position: relative;
    margin-top: 20px;

    @include media-up(md) {
      margin-top: 10px;
    }
  }

  .footer-banner {
    margin-top: 16px;
    margin-bottom: 20px;

    @include media-up(md) {
      margin-top: 32px;
    }

    .item {
      display: flex;
      column-gap: 8px;
      width: 100%;
      justify-content: center;

      @include media-up(md) {
        width: 600px;
        column-gap: 16px;
        justify-content: flex-start;
      }
    }

    .list img {
      width: auto;
      height: 56px;

      @include media-up(md) {
        height: 110px;
      }
    }
  }

  .footer-link__sns {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;

    @include media-up(md) {
      position: absolute;
      top: -65px;
      right: 0;
      -webkit-box-pack: end;
      -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
      justify-content: flex-end;
    }

    .footer-link__sns-item {
      margin: 0 6px;

      @include media-up(md) {
        margin: 0 0 0 10px;
      }

      a {
        display: block;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        line-height: 50px;
        background: $color-white;
        text-align: center;
        font-size: 22px;
        border: solid 1px $color-white;

        i {
          color: $color-main;
        }

        @include media-up(md) {
          &:hover {
            background: $color-main;

            i {
              color: $color-white;
            }
          }
        }
      }
    }
  }

  .footer-link__nav {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: 10px;

    @include media-up(md) {
      -webkit-box-pack: end;
      -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
      justify-content: flex-end;
      margin-top: 0;
    }

    .footer-link__nav-item {
      padding: 10px 10px 0;

      @include media-up(md) {
        padding: 0 0 0 35px;
      }

      a {
        color: $color-white;

        @include media-up(md) {
          font-size: 16px;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .copyright {
    font-size: 12px;
    text-align: center;
    color: $color-main-light;
    padding-top: 35px;

    @include media-up(md) {
      padding-top: 60px;
    }
  }
}
