/* stylelint-disable declaration-block-no-shorthand-property-overrides */
/* stylelint-disable declaration-block-no-duplicate-properties */
#index {
  overflow: hidden;

  .pointimage {
    .inner {
      position: relative;
    }

    figure {
      width: 40%;
      position: absolute;

      @include media-up(sm) {
        width: 30%;
      }

      @include media-up(lg) {
        width: 320px;
      }

      @include media-up(xl) {
        width: 420px;
      }

      @include media-up(xxl) {
        width: 560px;
      }
    }

    &.pointimage-left {
      figure {
        left: 0;
        top: -20px;

        @include media-up(sm) {
          top: 20px;
        }
      }
    }

    &.pointimage-right {
      figure {
        right: 0;
        top: -20px;

        @include media-up(lg) {
          top: -120px;
        }
      }
    }
  }

  .mainvis {
    position: relative;

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 70%;
      position: absolute;
      left: 0;
      top: 0;
      background: url(../img/main_bg.png);
      background-position: center bottom;
      background-repeat: no-repeat;
      background-size: cover;
      z-index: -1;

      @include media-up(md) {
        height: 60%;
      }

      @include media-up(lg) {
        height: 100%;
      }
    }

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 130px;
      position: absolute;
      left: 0;
      bottom: 30%;
      background: url(../img/mainvis_dot.png);
      background-position: center bottom;
      background-repeat: repeat-x;
      background-size: auto 100%;
      z-index: 0;

      @include media-up(md) {
        height: 180px;
        bottom: 40%;
      }

      @include media-up(lg) {
        bottom: 0;
      }
    }

    .l-container {
      position: relative;
      z-index: 1;
    }

    .mainvis-title {
      width: 75%;
      max-width: 400px;
      margin: 0 auto;
      padding-top: 130px;
      text-align: center;

      @include media-up(lg) {
        width: 56%;
        margin: 0;
        max-width: none;

        img {
          width: 66%;
        }
      }

      @include media-up(xl) {
        width: 660px;

        img {
          width: 400px;
        }
      }

      @include media-up(xxl) {
        width: 760px;

        img {
          width: 460px;
        }
      }
    }

    .mainvis-point {
      width: 100%;
      padding: 30px 0 30px;

      @include media-up(sm) {
        padding: 40px 0 40px;
      }

      @include media-up(lg) {
        width: 56%;
        padding: 50px 0 80px;
      }

      @include media-up(xl) {
        width: 660px;
      }

      @include media-up(xxl) {
        width: 760px;
      }
    }

    .mainvis-image {
      width: 60%;
      max-width: 360px;
      margin: 20px auto 0;

      img {
        margin-left: 7%;
      }

      @include media-up(sm) {
        margin: 30px auto 0;
      }

      @include media-up(lg) {
        width: 40%;
        max-width: none;
        margin: 0;
        position: absolute;
        right: 0;
        bottom: -120px;

        img {
          margin-left: 0;
        }
      }

      @include media-up(xl) {
        width: 480px;
        right: -10px;
      }

      @include media-up(xxl) {
        width: 560px;
        right: -150px;
        bottom: -200px;
      }
    }
  }

  .lead {
    position: relative;
    z-index: 1;

    .lead-text {
      line-height: 32px;

      .xl {
        display: none;
      }

      @include media-up(md) {
        font-size: 16px;
        line-height: 38px;
      }

      @include media-up(lg) {
        max-width: 56%;
      }

      @include media-up(xl) {
        width: 760px;
        max-width: none;

        .xl {
          display: inline;
        }
      }
    }
  }

  .about {
    margin: 60px 0 40px;
    position: relative;

    @include media-up(sm) {
      margin: 80px 0 60px;
    }

    @include media-up(md) {
      margin: 120px 0 60px;
    }

    @include media-up(lg) {
      margin: 210px 0;
    }

    &::before {
      content: "";
      display: block;
      width: 78%;
      height: 58%;
      background: $color-main-bg;
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: -1;
    }

    &::after {
      content: "";
      display: block;
      width: 78%;
      height: 180px;
      position: absolute;
      bottom: 0;
      right: 0;
      background: url(../img/mainvis_dot.png);
      background-position: center bottom;
      background-repeat: repeat-x;
      background-size: auto 100%;
      z-index: 0;
    }

    .about-title {
      width: 100%;
      max-width: 320px;
      margin: 0 auto;

      @include media-up(xl) {
        max-width: 410px;
      }
    }

    .about-lead {
      margin-top: 30px;
      font-size: 14px;
      line-height: 32px;

      .md {
        display: none;
      }

      @include media-up(sm) {
        margin-top: 45px;
        text-align: center;
        font-size: 15px;
      }

      @include media-up(md) {
        margin-top: 60px;
        font-size: 18px;
        line-height: 42px;

        .md {
          display: inline;
        }
      }
    }

    .about-point {
      margin-top: 30px;
      position: relative;
      z-index: 1;

      @include media-up(sm) {
        margin-top: 50px;
      }

      @include media-up(lg) {
        margin-top: 100px;
      }

      .about-point__image {
        width: 100%;
        max-width: 180px;
        margin: 0 auto 35px;

        @include media-up(sm) {
          margin: 0 auto 40px;
        }

        @include media-up(lg) {
          width: 270px;
          max-width: none;
          margin: 0 auto;
        }

        @include media-up(xl) {
          width: 370px;
        }
      }

      .about-point__item {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-top: 20px;
        padding: 15px 15px;
        border-radius: 5px;

        @include media-up(sm) {
          margin-top: 30px;
          padding: 25px 35px;
        }

        @include media-up(lg) {
          margin-top: 0;
          width: 350px;
          height: 350px;
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: center;
          -webkit-justify-content: center;
          -ms-flex-pack: center;
          justify-content: center;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -webkit-flex-flow: column;
          -ms-flex-flow: column;
          flex-flow: column;
          padding: 45px;
          border-radius: 50%;
          overflow: hidden;
          position: absolute;
        }

        @include media-up(xl) {
          width: 400px;
          height: 400px;
          padding: 65px;
        }

        .lead {
          text-align: center;
          font-size: 18px;
          padding-bottom: 5px;
          border-bottom: solid 1px $color-white;
          color: $color-white;
          font-family: $font-serif;

          @include media-up(sm) {
            font-size: 21px;
          }

          @include media-up(xl) {
            font-size: 24px;
          }
        }

        .text {
          margin-top: 10px;
          color: $color-white;
          font-size: 14px;

          @include media-up(lg) {
            margin-top: 15px;
          }

          @include media-up(xl) {
            font-size: 15px;
            margin-top: 20px;
          }
        }

        &.item1 {
          background-image: url(../img/about_point_item1_bg.png);

          @include media-up(lg) {
            right: -20px;
            top: -100px;
          }

          @include media-up(xl) {
            right: -20px;
            top: -90px;
          }
        }

        &.item2 {
          background-image: url(../img/about_point_item2_bg.png);

          @include media-up(lg) {
            left: -20px;
            top: 50%;
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
          }

          @include media-up(xl) {
            left: -40px;
          }
        }

        &.item3 {
          background-image: url(../img/about_point_item3_bg.png);

          @include media-up(lg) {
            right: -20px;
            bottom: -90px;
          }

          @include media-up(xl) {
            right: -80px;
            bottom: -50px;
          }
        }
      }
    }
  }

  .concept {
    position: relative;

    @include media-up(lg) {
      background: url(../img/concept_bg.png);
      background-position: left center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .l-container {
      background: url(../img/concept_content_bg.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      @include media-up(lg) {
        background: none;
      }
    }

    .concept-content {
      width: 100%;
      padding: 40px 0;

      @include media-up(lg) {
        padding: 60px 30px;
        width: 550px;
        background: url(../img/concept_content_bg.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }

      @include media-up(xl) {
        width: 670px;
        padding: 90px 45px;
      }

      .concept-content__title {
        width: 100%;
        max-width: 340px;
        margin: 0 auto;

        @include media-up(xl) {
          max-width: 450px;
        }
      }

      .concept-content__text {
        margin-top: 35px;

        @include media-up(lg) {
          margin-top: 50px;
        }

        @include media-up(xl) {
          margin-top: 80px;
        }

        .text {
          color: $color-white;
          font-size: 14px;
          line-height: 28px;

          &:nth-child(n + 2) {
            margin-top: 25px;
          }

          @include media-up(lg) {
            &:nth-child(n + 2) {
              margin-top: 40px;
            }
          }

          @include media-up(xl) {
            font-size: 16px;
            line-height: 32px;

            &:nth-child(n + 2) {
              margin-top: 60px;
            }
          }
        }
      }
    }

    .concept-content__image {
      @include media-up(lg) {
        display: none;
      }
    }
  }

  .history {
    padding: 60px 0 60px;
    position: relative;

    @include media-up(md) {
      padding: 90px 0 110px;
    }

    @include media-up(lg) {
      padding: 140px 0 190px;
    }

    .history-title {
      width: 100%;
      max-width: 240px;
      margin: 0 auto;

      @include media-up(md) {
        max-width: 300px;
      }

      @include media-up(xl) {
        max-width: 340px;
      }
    }

    .history-content {
      margin-top: 50px;

      .history-content__item {
        position: relative;
        margin-top: 40px;

        @include media-up(sm) {
          margin-top: 60px;
        }

        @include media-up(xl) {
          margin-top: 100px;
        }

        .history-item__item-image {
          width: 90%;
          max-width: 500px;
          margin: 0 auto;
          -webkit-transform: translateY(-20px);
          -ms-transform: translateY(-20px);
          transform: translateY(-20px);
          -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);

          @include media-up(lg) {
            width: 50%;
            max-width: none;
            margin: 0;
            -webkit-transform: none;
            -ms-transform: none;
            transform: none;
          }

          @include media-up(xl) {
            width: 600px;
          }
        }

        .history-item__item-textbody {
          width: 100%;
          padding: 30px 20px 40px;
          margin: 0 auto;
          border: solid 4px $color-main-bg;

          @include media-up(sm) {
            padding: 40px 40px 50px;
          }

          @include media-up(lg) {
            width: calc(50% + 20px);
            -webkit-transform: translateX(-20px);
            -ms-transform: translateX(-20px);
            transform: translateX(-20px);
            position: absolute;
            margin: 0;
            padding: 60px 45px 60px;
          }

          @include media-up(xl) {
            -webkit-transform: translateX(-45px);
            -ms-transform: translateX(-45px);
            transform: translateX(-45px);
            width: 645px;
            padding: 90px 80px 100px;
          }

          .num {
            position: absolute;
            top: -0.5em;
            left: 25px;
            font-size: 50px;
            line-height: 1;
            color: $color-main;
            font-family: $font-serif;

            @include media-up(lg) {
              left: 45px;
              font-size: 70px;
            }

            @include media-up(xl) {
              left: 70px;
              font-size: 90px;
            }
          }

          .text {
            font-size: 14px;
            line-height: 28px;

            @include media-up(xl) {
              font-size: 16px;
              line-height: 32px;
            }
          }
        }

        @include media-up(lg) {
          &:nth-child(odd) {
            .history-item__item-textbody {
              right: 0;
              top: 50%;
              -webkit-transform: translateY(-50%);
              -ms-transform: translateY(-50%);
              transform: translateY(-50%);
            }
          }

          &:nth-child(even) {
            .history-item__item-textbody {
              left: 0;
              top: 50%;
              -webkit-transform: translateY(-50%);
              -ms-transform: translateY(-50%);
              transform: translateY(-50%);
            }

            .history-item__item-image {
              margin-left: auto;
            }
          }
        }
      }
    }
  }

  .health {
    padding-bottom: 40px;
    position: relative;

    @include media-up(md) {
      padding-bottom: 70px;
    }

    @include media-up(lg) {
      padding-bottom: 100px;
    }

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: calc(100% - 120px);
      position: absolute;
      left: 0;
      bottom: 0;
      background: $color-main-bg;
      z-index: -2;
    }

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 180px;
      position: absolute;
      top: 120px;
      left: 0;
      background: url(../img/mainvis_dot.png);
      background-position: center bottom;
      background-repeat: repeat-x;
      background-size: auto 100%;
      z-index: 0;
      -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg);
      z-index: -1;
    }

    .health-title {
      margin-top: 25px;

      @include media-up(sm) {
        margin-top: 40px;
      }

      @include media-up(xl) {
        margin-top: 70px;
      }

      .ja {
        font-size: 24px;
        font-family: $font-serif;
        line-height: 38px;
        color: $color-main;
        font-weight: normal;

        @include media-up(sm) {
          font-size: 30px;
          line-height: 48px;
        }

        @include media-up(md) {
          font-size: 36px;
          line-height: 54px;
          letter-spacing: 0.1em;
        }

        @include media-up(xl) {
          font-size: 50px;
          line-height: 74px;
        }
      }

      .en {
        font-family: "Merriweather", serif;
        font-size: 13px;
        color: #d8c99f;
        margin-top: 8px;
        letter-spacing: 0.1em;

        @include media-up(md) {
          font-size: 14px;
          margin-top: 15px;
        }
      }
    }

    .health-text {
      margin-top: 25px;
      font-size: 14px;
      line-height: 28px;

      @include media-up(sm) {
        margin-top: 30px;
      }

      @include media-up(xl) {
        margin-top: 40px;
        font-size: 16px;
        line-height: 32px;
      }
    }

    .health-list {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      margin: 30px 0 0;

      @include media-up(sm) {
        margin: 40px 0 0;
      }

      @include media-up(md) {
        margin: 80px -15px 0;
      }

      .health-list__item {
        width: 100%;
        border: solid 8px $color-main-light;
        padding: 20px 15px;

        &:nth-child(n + 2) {
          margin-top: 15px;
        }

        @include media-up(sm) {
          padding: 25px 25px;

          &:nth-child(n + 2) {
            margin-top: 20px;
          }
        }

        @include media-up(md) {
          width: calc(33.3% - 8px);
          margin: 0 4px;
          padding: 25px 15px;

          &:nth-child(n + 2) {
            margin-top: 0;
          }
        }

        @include media-up(xl) {
          width: calc(33.3% - 30px);
          margin: 0 15px;
          border: solid 10px $color-main-light;
          padding: 40px 25px;
        }

        .title {
          .sub {
            color: $color-gray;
            font-family: $font-serif;
            font-size: 15px;
          }

          .main {
            color: $color-main;
            font-family: $font-serif;
            font-size: 20px;
            font-weight: normal;
            line-height: 1.4;

            @include media-up(xl) {
              font-size: 23px;
            }
          }
        }

        .text {
          margin-top: 15px;

          @include media-up(xl) {
            margin-top: 20px;
            line-height: 28px;
          }
        }
      }
    }
  }

  .environment {
    .environment-mainvis {
      width: 100%;
      height: 380px;
      background: url(../img/environment_mainvis.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      @include media-up(md) {
        height: 580px;
      }

      .l-container {
        position: relative;
      }

      .environment-mainvis__text {
        position: absolute;
        left: 50%;
        top: 40px;
        text-align: center;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        width: 100%;

        @include media-up(sm) {
          left: auto;
          right: 5%;
          -webkit-transform: none;
          -ms-transform: none;
          transform: none;
          text-align: right;
        }

        @include media-up(md) {
          right: 30px;
          top: 90px;
        }

        .ja {
          .ja-block {
            display: block;
            font-size: 6.2vw;
            color: $color-main;
            font-family: $font-serif;
            font-weight: normal;
            margin-bottom: 16px;

            @include media-up(sm) {
              font-size: 28px;
            }

            @include media-up(md) {
              font-size: 36px;
              margin-bottom: 22px;
            }

            @include media-up(xl) {
              font-size: 48px;
            }

            span {
              display: inline;
              background:
                -webkit-gradient(
                  linear,
                  left top,
                  left bottom,
                  from(transparent),
                  color-stop(0%, $color-white)
                );
              background: -webkit-linear-gradient(transparent 0%, $color-white 0%);
              background: -o-linear-gradient(transparent 0%, $color-white 0%);
              background: linear-gradient(transparent 0%, $color-white 0%);
              font-weight: bold;
              padding: 8px 10px 8px 20px;

              @include media-up(md) {
                padding: 10px 10px 10px 30px;
              }
            }
          }
        }

        .en {
          font-family: "Merriweather", serif;
          font-size: 2.5vw;
          color: #d8c99f;
          padding-top: 10px;

          @include media-up(sm) {
            font-size: 11px;
          }

          @include media-up(xl) {
            font-size: 14px;
            letter-spacing: 0.1em;
          }

          span {
            display: inline;
            background:
              -webkit-gradient(
                linear,
                left top,
                left bottom,
                from(transparent),
                color-stop(0%, $color-white)
              );
            background: -webkit-linear-gradient(transparent 0%, $color-white 0%);
            background: -o-linear-gradient(transparent 0%, $color-white 0%);
            background: linear-gradient(transparent 0%, $color-white 0%);
            font-weight: bold;
            padding: 10px 10px;

            @include media-up(md) {
              padding: 10px 25px;
            }

            @include media-up(xl) {
              padding: 14px 40px;
            }
          }
        }
      }
    }

    .environment-content {
      .environment-content__item {
        padding: 30px 0;
        margin-top: 30px;
        position: relative;

        @include media-up(sm) {
          padding: 40px 0;
          margin-top: 60px;
        }

        @include media-up(xl) {
          padding: 55px 0;
          margin-top: 100px;
        }

        &::before {
          content: "";
          display: block;
          width: 65%;
          height: 100%;
          background: $color-main-bg;
          position: absolute;
          top: 0;
          z-index: -1;
        }

        .l-container {
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          -webkit-flex-wrap: wrap;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          -webkit-box-align: center;
          -webkit-align-items: center;
          -ms-flex-align: center;
          align-items: center;
        }

        .environment-content__item-image {
          width: 100%;
          max-width: 500px;
          margin: 25px auto 0;
          -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);

          @include media-up(sm) {
            margin: 40px auto 0;
          }

          @include media-up(lg) {
            width: 40%;
            margin: 0;
          }

          @include media-up(xl) {
            width: 540px;
          }
        }

        .environment-content__item-textbody {
          width: 100%;
          max-width: 650px;
          margin: 0 auto;

          @include media-up(lg) {
            width: 60%;
            max-width: none;
            margin: 0;
            padding: 0 0 0 40px;
          }

          @include media-up(xl) {
            width: calc(100% - 540px);
            padding: 0 65px;
          }

          .title {
            font-size: 18px;
            color: $color-main;
            font-family: $font-serif;
            font-weight: normal;

            @include media-up(sm) {
              font-size: 22px;
              letter-spacing: 0.1em;
            }

            @include media-up(xl) {
              font-size: 28px;
              line-height: 44px;
            }
          }

          .text {
            margin-top: 20px;

            @include media-up(xl) {
              margin-top: 30px;
              font-size: 16px;
              line-height: 32px;
            }
          }
        }

        &:nth-child(odd) {
          &::before {
            right: 0;
          }

          @include media-up(lg) {
            .l-container {
              -webkit-box-orient: horizontal;
              -webkit-box-direction: reverse;
              -webkit-flex-flow: row-reverse;
              -ms-flex-flow: row-reverse;
              flex-flow: row-reverse;
            }
          }
        }

        &:nth-child(even) {
          &::before {
            left: 0;
          }

          @include media-up(lg) {
            .environment-content__item-textbody {
              padding: 0 40px 0 0;
            }
          }

          @include media-up(xl) {
            .environment-content__item-textbody {
              padding: 0 65px;
            }
          }
        }
      }
    }
  }

  .store {
    margin: 40px 0 40px;

    @include media-up(md) {
      margin: 60px 0 70px;
    }

    @include media-up(lg) {
      margin: 100px 0 80px;
    }

    .store-mainvis {
      padding: 30px 0;
      background: url(../img/store_mainvis_bg.png);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: bottom center;

      @include media-up(md) {
        padding: 50px 0;
      }

      @include media-up(lg) {
        padding: 70px 0;
      }

      .l-container {
        position: relative;
      }

      .store-mainvis__title {
        width: 100%;

        @include media-up(lg) {
          width: 760px;
        }

        @include media-up(xl) {
          margin-left: 50px;
        }

        .title {
          width: 56%;
          max-width: 400px;
          margin: 0 auto;
        }

        .text {
          margin-top: 25px;
          width: 88%;

          @include media-up(md) {
            margin-top: 35px;
          }

          @include media-up(lg) {
            width: 100%;
          }
        }
      }

      .store-mainvis__image {
        width: 18%;
        position: absolute;
        right: 5%;
        bottom: -40%;

        @include media-up(md) {
          right: 30px;
        }

        @include media-up(lg) {
          width: 20%;
        }

        @include media-up(xl) {
          width: 280px;
          bottom: -240px;
        }
      }
    }

    // 改修用追加 2023.04.20

    .store-mainvis2 {
      padding: 48px 0;
      background: url(../img/store_mainvis_bg.png);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: bottom center;

      @include media-up(sm) {
        padding: 64px 0;
      }

      @include media-up(md) {
        padding: 100px 0;
      }

      @include media-up(lg) {
        padding: 140px 0;
      }

      @include media-up(xl) {
        padding: 180px 0;
      }

      .l-container2 {
        position: relative;

        @include media-up(xl) {
          max-width: 1200px;
          margin: 0 auto;
        }
      }

      .store-mainvis__title2 {
        width: 100%;

        @include media-up(lg) {
          width: 760px;
        }

        @include media-up(xl) {
          margin-left: 50px;
        }

        .title2 {
          width: 56%;
          max-width: 400px;
          margin-left: 40px;

          @include media-up(md) {
            margin-left: 80px;
          }

          @include media-up(xxl) {
            margin-left: 0;
          }
        }
      }

      .store-mainvis__image2 {
        width: 20%;
        position: absolute;
        right: 5%;
        bottom: -70px;

        @include media-up(sm) {
          right: 30px;
          bottom: -90px;
        }

        @include media-up(md) {
          right: 60px;
          bottom: -160px;
        }

        @include media-up(lg) {
          width: 20%;
          right: 110px;
          bottom: -240px;
        }

        @include media-up(xl) {
          width: 280px;
          bottom: -240px;
        }

        @include media-up(xl) {
          right: 0;
          bottom: -320px;
        }
      }
    }

    .store-text {
      margin-top: 35px;

      @include media-up(sm) {
        margin-top: 45px;
      }

      @include media-up(lg) {
        margin-top: 60px;
      }

      .store-text__main {
        font-size: 28px;
        color: $color-main;
        font-family: $font-serif;
        line-height: 1;

        span {
          font-size: 45px;
        }

        @include media-up(sm) {
          font-size: 32px;

          span {
            font-size: 55px;
          }
        }

        @include media-up(md) {
          font-size: 42px;
          letter-spacing: 0.1em;

          span {
            font-size: 70px;
          }
        }
      }

      .store-text__sub {
        font-size: 15px;
        font-family: $font-serif;
        margin-top: 6px;

        br {
          display: none;
        }

        @include media-up(md) {
          font-size: 18px;

          br {
            display: inline;
          }
        }

        @include media-up(lg) {
          font-size: 20px;
        }
      }

      .ch-text {
        font-family: Meiryo, メイリオ, sans-serif;
      }
    }

    .store-pricelist {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-flow: column;
      -ms-flex-flow: column;
      flex-flow: column;
      margin: 30px auto 0;

      @include media-up(sm) {
        margin: 45px auto 0;
      }

      @include media-up(md) {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-flow: row;
        -ms-flex-flow: row;
        flex-flow: row;
        margin: 50px -5px 0;
      }

      @include media-up(lg) {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-flow: row;
        -ms-flex-flow: row;
        flex-flow: row;
        margin: 65px -15px 0;
      }

      .store-pricelist__item {
        width: 100%;
        max-width: 400px;
        margin: 0 auto;

        &:nth-child(n + 2) {
          margin-top: 20px;
        }

        @include media-up(sm) {
          &:nth-child(n + 2) {
            margin-top: 30px;
          }
        }

        @include media-up(md) {
          padding: 0 5px;

          &:nth-child(n + 2) {
            margin-top: 0;
          }
        }

        @include media-up(lg) {
          width: 33.3%;
          max-width: none;
          margin: 0;
          padding: 0 15px;
        }

        .inner {
          -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);

          @include media-up(lg) {
            -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
            box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
          }
        }

        .content {
          padding: 25px 30px 25px;
          position: relative;

          @include media-up(md) {
            padding: 20px 20px 15px;
          }

          @include media-up(lg) {
            padding: 30px 40px 25px;
          }
        }

        .point {
          width: 25%;
          position: absolute;
          top: 20px;
          left: 1px;

          @include media-up(sm) {
            width: 94px;
          }

          @include media-up(md) {
            width: 64px;
          }

          @include media-up(lg) {
            width: 84px;
            left: 3px;
          }

          @include media-up(xl) {
            width: 94px;
            left: 6px;
          }
        }

        .image {
          max-width: 280px;
          margin: 0 auto;
        }

        .btn {
          margin-top: 15px;

          a {
            display: block;
            background: $color-orange;
            width: 100%;
            max-width: 280px;
            margin: 0 auto;
            height: 58px;
            line-height: 1;
            font-weight: bold;
            border-radius: 10px;
            font-size: 18px;
            color: $color-white;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -webkit-justify-content: center;
            -ms-flex-pack: center;
            justify-content: center;
            border: solid 2px $color-orange;

            i {
              padding-right: 8px;
            }

            @include media-up(md) {
              &:hover {
                color: $color-orange;
                background: $color-white;
              }
            }

            @include media-up(lg) {
              height: 65px;
              font-size: 22px;
            }
          }
        }
      }
    }

    .store-attention {
      margin-top: 25px;
      color: $color-gray;

      @include media-up(md) {
        margin-top: 35px;

        .sp {
          display: none;
        }
      }
    }
  }
}

#index.english {
  .pointimage {
    &.pointimage-right {
      figure {
        top: -20px;

        @include media-up(lg) {
          top: -120px;
        }
      }
    }
  }

  .about {
    .about-title {
      max-width: 520px;

      @include media-up(xl) {
        max-width: 800px;
      }
    }

    .about-point {
      .about-point__item {
        @include media-up(lg) {
          width: 354px;
          height: 354px;
        }

        @include media-up(xl) {
          width: 410px;
          height: 410px;
        }

        .lead {
          line-height: 1.2;

          @include media-up(lg) {
            font-size: 18px;
          }

          @include media-up(xl) {
            font-size: 24px;
          }
        }

        .text {
          font-size: 13px;
          margin-top: 10px;

          @include media-up(lg) {
            line-height: 1.5;
          }

          @include media-up(xl) {
            line-height: 1.6;
          }
        }
      }
    }
  }

  .concept {
    .concept-content {
      .concept-content__title {
        max-width: 540px;

        @include media-up(xl) {
          max-width: none;
        }
      }
    }
  }

  .history {
    .history-title {
      max-width: 460px;

      @include media-up(xl) {
        max-width: 510px;
      }
    }
  }

  .environment {
    .environment-mainvis {
      .environment-mainvis__text {
        .ja {
          .ja-block {
            display: block;
            font-size: 6vw;
            color: $color-main;
            font-family: $font-serif;
            font-weight: normal;
            margin-bottom: 10px;

            @include media-up(sm) {
              font-size: 28px;
            }

            @include media-up(md) {
              font-size: 36px;
            }

            @include media-up(xl) {
              font-size: 48px;
            }

            span {
              padding: 8px 10px 8px 10px;

              @include media-up(md) {
                padding: 10px 30px 10px 30px;
              }
            }
          }
        }
      }
    }

    .environment-content {
      .environment-content__item {
        .environment-content__item-textbody {
          .title {
            font-size: 16px;

            @include media-up(sm) {
              font-size: 20px;
              letter-spacing: 0;
            }

            @include media-up(xl) {
              font-size: 24px;
              line-height: 40px;
            }
          }
        }
      }
    }
  }

  .health {
    .health-title {
      .ja {
        font-size: 20px;

        br {
          display: none;
        }

        @include media-up(sm) {
          font-size: 23px;
          line-height: 36px;

          br {
            display: inline;
          }
        }

        @include media-up(md) {
          font-size: 30px;
          line-height: 48px;
          letter-spacing: 0;
        }

        @include media-up(xl) {
          font-size: 40px;
          line-height: 64px;
        }
      }
    }
  }

  .store {
    .store-text {
      .store-text__main {
        font-size: 20px;

        span {
          font-size: 40px;
        }

        @include media-up(sm) {
          font-size: 26px;

          span {
            font-size: 45px;
          }
        }

        @include media-up(md) {
          font-size: 32px;
          letter-spacing: 0;

          span {
            font-size: 60px;
          }
        }

        @include media-up(lg) {
          font-size: 42px;

          span {
            font-size: 70px;
          }
        }
      }
    }

    .store-pricelist {
      .store-pricelist__item {
        .point {
          width: 23%;
          position: absolute;
          top: 90px;
          left: 3px;

          @include media-up(sm) {
            width: 94px;
          }

          @include media-up(md) {
            width: 74px;
            top: 90px;
          }

          @include media-up(lg) {
            width: 74px;
            left: 3px;
          }

          @include media-up(xl) {
            width: 88px;
            left: 5px;
          }
        }
      }
    }
  }
}
