.header {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1000;

  .header-content {
    padding-top: 20px;

    @include media-up(md) {
      padding-top: 50px;
    }

    .header-content__logo {
      width: 100%;
      max-width: 250px;
      margin-top: 20px;

      @include media-up(md) {
        width: 300px;
        max-width: none;
        margin-top: 0;
      }

      .header-content__img {
        height: 40px;
        width: auto;

        @include media-up(md) {
          height: 50px;
        }
      }
    }

    .l-container {
      @include media-up(md) {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        -webkit-flex-flow: row-reverse;
        -ms-flex-flow: row-reverse;
        flex-flow: row-reverse;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
      }
    }

    .header-content__langage {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: end;
      -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
      justify-content: flex-end;

      .item {
        a,
        span {
          display: block;
          width: 90px;
          height: 30px;
          line-height: 26px;
          text-align: center;
          border-color: $color-main;
          border-style: solid;
          border-width: 1px 1px 1px 0;

          @include media-up(md) {
            width: 110px;
            height: 35px;
            line-height: 30px;
          }

          @include media-down(xs) {
            width: 50px;
            height: 30px;
            font-size: 10px;
          }
        }

        &:first-child {
          a,
          span {
            border-width: 1px 1px 1px 1px;
          }
        }

        a {
          background: $color-white;
          color: $color-main;

          @include media-up(md) {
            &:hover {
              background: $color-main;
              color: $color-white;
            }
          }
        }

        span {
          background: $color-main;
          color: $color-white;
        }
      }
    }
  }
}
