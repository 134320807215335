// color
$color-white: #fff;
$color-black: #353e47;
$color-gray: #676767; //text main
$color-gray-light: #e1e8ed; //border
$color-gray-ultralight: #eaeaea; //background
$color-main: #b69732;
$color-main-dark: #0016ff;
$color-main-light: #ebe1c5;
$color-main-ultralight: #f8fafe;
$color-main-bg: #f8f4ea;
$color-pink: #ed3e73;
$color-red: #ff5252;
$color-yellow: #fad508;
$color-orange: #de9610;

// font-family
$font-sans: "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "Noto Sans JP","SF Pro SC","SF Pro Text", Meiryo, メイリオ, sans-serif;
$font-serif: "Hiragino Mincho ProN","Hiragino Mincho Pro","SimSun",HGS明朝E,メイリオ,Meiryo,serif;

// breakpoints
$breakpoint-up: (
  'xs': 'screen and (min-width: 425px)',
  'sm': 'screen and (min-width: 554px)',
  'md': 'screen and (min-width: 768px)',
  'lg': 'screen and (min-width: 992px)',
  'xl': 'screen and (min-width: 1260px)',
  'xxl': 'screen and (min-width: 1460px)',
) !default;

$breakpoint-down: (
  'xs': 'screen and (max-width: 424px)',
  'sm': 'screen and (max-width: 553px)',
  'md': 'screen and (max-width: 767px)',
  'lg': 'screen and (max-width: 991px)',
  'xl': 'screen and (max-width: 1259px)',
  'xxl': 'screen and (max-width: 1459px)',
) !default;

@mixin media-up($breakpoint: md) {
  @media #{map-get($breakpoint-up, $breakpoint)} {
    @content;
  }
}

@mixin media-down($breakpoint: md) {
  @media #{map-get($breakpoint-down, $breakpoint)} {
    @content;
  }
}
