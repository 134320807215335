html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  font-size: 14px;
  line-height: 1.6;
  color: $color-black;
  font-family: $font-sans;
  height: 100%;
  width: 100%;
  text-align: left;
  margin: 0;
  padding: 0;
  background: $color-white;
}

img {
  vertical-align: bottom;
  width: 100%;
  height: auto;
}

table {
  border-collapse: collapse;
}

a {
  color: $color-black;
  text-decoration: none;
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

a:hover {
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.legacy-ie {
  background: #eee;
  width: 100%;
  padding: 100px 0;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  z-index: 10000;
}

.legacy-ie a {
  text-decoration: none;
}

.noscript {
  width: 100%;
  padding: 100px 0;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  z-index: 10000;
}
